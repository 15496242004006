import { format, isValid, parse } from 'date-fns';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const TIME_FORMAT = 'HH:mm';

export const formatTime = (timeString, formatString = TIME_FORMAT) => {
    const parsedTime = parse(timeString, 'HH:mm:ss', new Date());
    return isValid(parsedTime) ? format(parsedTime, formatString) : null;
};

export const formatDateFromIsoString = (dateString) => {
    if (!dateString) return ''; // Checks for null avoid return 1/1/1970
    const date = new Date(dateString);
    return isValid(date) ? format(date, DATE_FORMAT) : '';
};
