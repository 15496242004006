import styles from './AdditionalInfo.module.css';

function AdditionalInfo({ option, hasAlerts }) {
    const isDuplicate = option.duplicate;
    const { id, identificationNumber, identificationType } = option;
    return (
        (hasAlerts || isDuplicate) && (
            <div className={styles.container}>
                {id && <p>{`ID: ${id}`}</p>}
                {identificationNumber && <p>{`${identificationType}: ${identificationNumber}`}</p>}
            </div>
        )
    );
}

export default AdditionalInfo;
