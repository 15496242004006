import { call, all, take, takeLatest, put, select, takeEvery, actionChannel, fork, takeLeading } from 'redux-saga/effects';

import { store } from 'store/store';
import { eventChannel } from 'redux-saga';
import { transactionActions } from './transactionsSlice';
import * as aiqConfig from 'config';
import * as transactionSelectors from 'store/transactionsSlice/transactionsSelectors';
import { desksActions } from 'store/desksSlice/desksSlice';
import { snackbarActions } from 'store/snackbarsSlice/snackbarsSlice';
import axios from 'axios';
import { tableControlActions } from 'store/tableControlSlice/tableControlSlice';
import { formControlActions } from 'store/formControlSlice/formControlSlice';
import { notificationHandler, transformCameraConfiguration } from 'utils/utils';
import { selectFormsObject, selectIsTransactionInProcessingStateById } from './../formControlSlice/formControlSelectors';
import { selectTransactions } from './transactionsSelectors';
import { selectAllTablesData } from '../tableControlSlice/tableControlSelectors';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { selectUser } from 'store/authSlice/authSelectors';
import { selectIsActiveDeskConfirmed, selectCurrentDesk, selectExpectedFinishedTransactionMillis } from 'store/desksSlice/desksSelectors';
import { sendLocalStorageSignal } from 'utils/sessionStorageHandlers';
import { talkManager } from 'services/Talk/TalkManager';
import { axiosAiq } from 'config';
import { streamsUseReducerDispatch } from 'hooks/useStreams/useStreams';
import { TRANSACTION_PAGE_TABS } from 'config';
import { vinCheckPageStateUpdateFns } from 'pages/VinCheck';
import { initializeNewForm } from 'store/formControlSlice/formControlSagas';
import { isDeskAvailable } from 'store/desksSlice/desksSagas';
import {
    sharedWorkerActions,
    sharedWorker as sharedWorkerObj,
    sharedWorkerMessageType as messageType,
    stompTopics,
} from 'workers/sharedWorker/utils/utils';
import getSharedWorker from '../../workers/sharedWorker/utils/sharedWorker-factory';
import { perf } from './performance';

const finishedTransactionIdsQueue = [];
let transactionIdsMarkedForDropping = [];

const allDesksTopic = '/topic/all-desks';

const {
    DIVISION_TAB: { ID: divisionTabId },
    REOPENED_TRANSACTION_TAB: { ID: reopenedTransactionTabId },
    VIDEO_WALL_TAB: { ID: videoWallTabId, DUMMY_TRANSACTION_ID: videoWallTransactionId },
} = TRANSACTION_PAGE_TABS;

let stompBrokerTopics = {};

const videoWallTransactions = new Map();

export function* handleStompBrokerResponse(payload) {
    const command = payload.command;

    switch (command) {
        case 'CONNECTED':
            yield put(transactionActions.stompBrokerConnectionSuccess());
            break;

        case 'MESSAGE':
            yield call(handleStompBrokerMessage, payload);
            break;

        case 'AIQ.FRONTEND.WEBSOCKET_ERROR':
            yield call(handleWebSocketError);
            break;

        case 'ERROR':
        case 'AIQ.FRONTEND.STOMP_BROKER_DISCONNECTED':
            yield call(handleError, command);
            break;

        default:
            console.log('PAYLOAD MISSED SWITCH STATMENT IN TRANSACTIONS SAGA', payload.command);
    }
}

///handleStompBrokerResponse utils
function* handleStompBrokerMessage(payload) {
    let data = JSON.parse(payload.body);
    const topic = payload.headers?.subscription;

    const { username: activeUser } = yield select(selectUser);
    if (topic === allDesksTopic) {
        yield put(desksActions.replaceDesk(data.desk));
        const { id: currentDeskId, name: currentDeskName } = yield select(selectCurrentDesk);
        const isActiveDeskConfirmed = yield select(selectIsActiveDeskConfirmed);
        const incomingDeskId = data?.desk.id;
        const incomingDeskUser = data?.desk?.sessions?.[0]?.account?.username;
        const isReceivingTransactionsEnabled = yield select(transactionSelectors.selectIsReceivingTransactionsEnabled);

        switch (true) {
            case !isActiveDeskConfirmed:
                if (incomingDeskId !== currentDeskId || !incomingDeskUser) return;
                if (incomingDeskUser !== activeUser) {
                    yield call(leaveDesk, false);
                    yield call(notificationHandler, {
                        title: `${currentDeskName.toUpperCase()} is taken by ${incomingDeskUser}, please select different desk.`,
                    });
                }

                yield put(desksActions.setIsActiveDeskConfirmed(true));
                return;

            case incomingDeskId === currentDeskId && !incomingDeskUser && isReceivingTransactionsEnabled:
                yield call(leaveDesk);
                break;
            case incomingDeskId !== currentDeskId && incomingDeskUser === activeUser:
                yield put(desksActions.selectDesk(data.desk));
                break;

            default:
        }

        return;
    }

    if (topic.startsWith('/topic/vin-locations.')) {
        const incomingTransactionId = data?.['vin_transaction_id'];
        vinCheckPageStateUpdateFns.forEach((setProcessingActions) =>
            setProcessingActions((state) => {
                const { transactionId } = state;
                if (incomingTransactionId === transactionId) {
                    return { ...state, isTransactionProcessed: true, requestInProgress: { ...state.requestInProgress, sendingSnapshots: false } };
                }
                return state;
            })
        );
    }

    if (topic === stompTopics.cameraUpdates.topicUrl) {
        yield call(updateCameraConfig, data, kurentoManager.getTransactionIdsByCameraId(data.id));
        return;
    }

    if (data['@c'] === '.DeskCameraStreamMessage') {
        yield call(updateCameraConfig, data.camera, data?.['transaction_ids']);
        return;
    }

    if (data['@c'] === '.DeskAssignedMessage') {
        console.log(
            `handleStompBrokerMessage(): DeskAssignedMessage received by browser tab, transactionId=${data?.truck_transaction_id}, version=${data?.version}`
        );
        perf.mark(data, perf.markNames.received);
        yield put(transactionActions.deskAssignedMessageReceived(data));
        yield put(transactionActions.getNameForTransaction(data));
    }
    if (data['@c'] === '.DeskAotMessage') {
        const camConfigList = data.cameras;
        const operation = data.op === 'DETECT' ? 'activateAot' : data.op === 'CANCEL' ? 'cancelAot' : '';
        const camIds = new Set(camConfigList.map((camConfig) => camConfig.id));
        console.log(`handleStompBrokerMessage(): DeskAotMessage received, operation=${data.op} camIds=[${[...camIds]}]`);
        operation && camConfigList.map((camConfig) => kurentoManager[operation](activeUser, transformCameraConfiguration(camConfig)));
    }

    if (data['@c'] === '.TransactionStatusVideoWallMessage') {
        yield put(transactionActions.removedTransactionDetection(data));
        const timeouId = videoWallTransactions.get(data.camera_id);
        timeouId && clearTimeout(timeouId);
        videoWallTransactions.delete(data.camera_id);
    }

    if (data['@c'] === '.VideoWallSubmittedAgentInformation') {
        yield put(transactionActions.setTransactionDetectionAgentInformation(data));
    }

    if (data['@c'] === '.VideoWallCameraStreamMessage') {
        const camConfig = transformCameraConfiguration(data.camera);
        kurentoManager.updateCameraConfig(camConfig).then(() => {
            const transactionIdsArr = kurentoManager.getTransactionIdsByCameraId(camConfig.camId);
            store.dispatch(
                transactionActions.setCameraConfig({
                    transactionIdsArr,
                    camConfig,
                    activeOnVideoWall: kurentoManager.isActiveOnVideoWall(camConfig.camId),
                })
            );
        });
    }

    if (data['@c'] === '.VideoWallNewTransactionMessage') {
        const timeoutId = setTimeout(() => {
            videoWallTransactions.delete(data.camera_id);
            store.dispatch(transactionActions.removedTransactionDetection(data));
        }, 150000);
        videoWallTransactions.set(data.camera_id, timeoutId);
        yield put(transactionActions.setTransactionDetectionList(data));
    }
}

function* updateCameraConfig(newCameraConfiguration, transactionIdsArr = []) {
    const camConfig = transformCameraConfiguration(newCameraConfiguration);
    if (!camConfig.videoData.streamType) camConfig.videoData.streamType = 'TEST';
    yield kurentoManager.updateCameraConfig(camConfig).then(() => {
        transactionIdsArr &&
            transactionIdsArr.length &&
            store.dispatch(
                transactionActions.setCameraConfig({
                    transactionIdsArr,
                    camConfig,
                    activeOnVideoWall: kurentoManager.isActiveOnVideoWall(camConfig.camId),
                })
            );

        streamsUseReducerDispatch.length &&
            streamsUseReducerDispatch.forEach((dispatch) => dispatch({ type: 'editStreamConfig', payload: camConfig }));
    });

    return;
}

export function* getSaturationCameras(deskId) {
    try {
        const response = yield axiosAiq(`/locations/desk/${deskId}/cameras/saturation?preload=true&connect=true`);
        yield call(preconfigureCameraStreams, response.data);
    } catch (err) {
        yield call(notificationHandler, {
            err,
            title: `Error getting saturation cameras`,
        });
    }
}

export function* getVideoWallCameras({ payload }) {
    try {
        yield put(transactionActions.setFetchingVideoWallCamerasInProgress(true));
        const response = yield axiosAiq.get(`/locations/desk/${payload}/cameras/saturation?page=0&size=200&connect=true`);
        yield call(preconfigureCameraStreams, response.data);
        yield put(transactionActions.setVideoWallCameras(response.data));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            title: `Error getting video wall cameras`,
        });
    }
    yield put(transactionActions.setFetchingVideoWallCamerasInProgress(false));
}

async function preconfigureCameraStreams(camConfigList) {
    const user = store.getState()?.auth?.user?.username;
    await (kurentoManager.preconfigeCamerasPromise = Promise.allSettled(
        camConfigList.map((camConfig) => kurentoManager.create(user, transformCameraConfiguration(camConfig), true))
    ));
    const camIds = new Set(camConfigList.map((camConfig) => camConfig.id));
    console.log(`preconfigureCameras(): Success preconfigured ${camIds.size} cameras, ids=[${[...camIds]}]`);
    kurentoManager.preconfigeCamerasPromise = null;
}

function* toggleVideoWallEnabled({ payload }) {
    yield put(transactionActions.setChangingOperationalModeInProgress(true));
    yield put(transactionActions.setIsVideoWallEnabled(payload));
    sessionStorage.setItem(aiqConfig.sessionStorageKeys.videoWallEnabled, payload);
    sharedWorkerActions.windowModeChange({ subscribe: payload, topicName: 'videoWall' });
    const currentDesk = yield select(selectCurrentDesk);
    const activeDeskId = currentDesk?.id;
    if (!activeDeskId) {
        yield put(transactionActions.setChangingOperationalModeInProgress(false));
        return;
    }
    if (payload === false) {
        yield put(transactionActions.unsubscribeFromStompBrokerTopic('videoWall'));
        yield put(transactionActions.removeVideoWallCameras());
        yield kurentoManager.stopStreamsForTab(videoWallTabId, videoWallTransactionId);
    }
    if (payload === true) yield call(getVideoWallCameras, { payload: activeDeskId });
    yield put(transactionActions.setChangingOperationalModeInProgress(false));
}

function* toggleIsReceivingTransactionsEnabled({ payload }) {
    yield put(transactionActions.setChangingOperationalModeInProgress(true));
    yield put(transactionActions.setIsReceivingTransactionsEnabled(payload));
    sharedWorkerActions.windowModeChange({ subscribe: payload, topicName: stompTopics.transactionDesk });
    sessionStorage.setItem(aiqConfig.sessionStorageKeys.receivingTransactionsEnabled, payload);
    const currentDesk = yield select(selectCurrentDesk);
    const activeDeskId = currentDesk?.id;
    if (!activeDeskId) {
        yield put(transactionActions.setChangingOperationalModeInProgress(false));
        return;
    }

    if (payload === false) {
        yield put(desksActions.setIsActiveDeskConfirmed(true));
        const isVideoWallEnabled = yield select(transactionSelectors.selectIsVideoWallEnabled);
        //TO DO  same logic  as in closeTransactionPanel fn (in case closeAllTabs=true) modify it to reuse same logic (note: keep in mind - closeTransctionPanel has its reducer fn inside store)
        yield put(transactionActions.setActiveTab(isVideoWallEnabled ? videoWallTabId : divisionTabId));
        const transacions = yield select(transactionSelectors.selectTransactions);
        for (let transacion of transacions) yield put(formControlActions.removeForm(transacion.id));
        yield put(transactionActions.setTransactions([]));
        yield put(transactionActions.setUpdatingTransactionInProgress({ initialState: true }));
        yield put(transactionActions.setHasReopenedTransaction(false));
        yield put(tableControlActions.setAllTablesData({}));
        //
        yield kurentoManager.stopAllStreamsExcludeTab(videoWallTabId);
        yield talkManager.stopSession();
    }
    if (payload === true) {
        const { desk, takenBy } = yield call(isDeskAvailable, currentDesk);
        if (desk) {
            yield call(getSaturationCameras, desk.id);
            yield call(subscribeToStompBrokerTopic, { payload: { topic: stompTopics.transactionDesk, url: `/topic/desks.${activeDeskId}` } });
            yield put(desksActions.setIsActiveDeskConfirmed(false));
        } else {
            yield call(notificationHandler, {
                variant: 'info',
                title: `Transaction mode is not currently available for ${currentDesk.name.toUpperCase()}, it is taken ${
                    takenBy ? ` by ${takenBy}` : ''
                }`,
            });
            yield put(transactionActions.setIsReceivingTransactionsEnabled(false));
        }
    }
    yield put(transactionActions.setChangingOperationalModeInProgress(false));
}

function* handleDeskAssignedMessage(data) {
    const transactionId = data?.truck_transaction_id;
    const locationId = data?.location_id;
    const gateId = data?.gate_id;
    const version = data?.version;
    const reopened = data?.reopened;
    const automation = data?.automation;

    const currentTransaction = yield select(transactionSelectors.selectTranasctionById(transactionId));
    const currentVersion = currentTransaction?.version;

    console.log(
        `handleDeskAssignedMessage(): Starting to process transactionId=${transactionId}, currentVersion=${currentVersion}, incomingVersion=${version} `
    );

    const isTransactionInProcessingState = yield select(selectIsTransactionInProcessingStateById(transactionId));
    const allowNewTransactionInitialization =
        !currentTransaction && !isTransactionInProcessingState && (!finishedTransactionIdsQueue.includes(transactionId) || reopened);

    if (allowNewTransactionInitialization) {
        console.log(`handleDeskAssignedMessage(): Initializing new transaction, transactionId=${transactionId}`);
        yield put(transactionActions.handleNewTransaction(data));
        console.log(`handleDeskAssignedMessage(): Initializing form and getting encoded images, transactionId=${transactionId}`);
        perf.mark(data, perf.markNames.initializeNewFormStart);
        yield call(initializeNewForm, transactionId);
        perf.mark(data, perf.markNames.initializeNewFormEnd);
        console.log(`handleDeskAssignedMessage(): Getting relays, transactionId=${transactionId}`);
        perf.mark(data, perf.markNames.fetchRelaysStart);
        yield call(getRelaysConfig, transactionId, gateId);
        perf.mark(data, perf.markNames.fetchRelaysEnd);

        yield put(
            tableControlActions.fetchTableDataStart({
                tableId: locationId,
                locationId,
                pageNum: 0,
                fetchLocationAnnouncements: data.gate_direction === 'OUT',
            })
        );
        console.log(`handleDeskAssignedMessage(): Initialized new transaction for transactionId=${transactionId}`);
    } else if (version > currentVersion && automation) {
        console.log(`handleDeskAssignedMessage(): Updating transaction, transactionId=${transactionId}`);
        perf.mark(data, perf.markNames.updateTransactionStart);
        yield call(updateTransaction, transactionId);
        perf.mark(data, perf.markNames.updateTransactionEnd);
        perf.mark(data, perf.markNames.updateCamerasStart);
        yield call(updateTransactionCameras, [...data.cameras.level1, ...data.cameras.level2]);
        perf.mark(data, perf.markNames.updateCamerasEnd);
    } else if (currentVersion && version === 0) {
        console.log(`handleDeskAssignedMessage(): Received base transaction version with delay, updating camera streams....`);
        perf.mark(data, perf.markNames.updateCamerasStart);
        yield call(updateTransactionCameras, [...data.cameras.level1, ...data.cameras.level2]);
        perf.mark(data, perf.markNames.updateCamerasEnd);
    } else {
        currentTransaction &&
            console.log(
                `handleDeskAssignedMessage(): Current transaction is up to date. Aborting! currentVersion=${currentVersion}, incomingVersion=${version}`
            );

        !currentTransaction &&
            console.log(`handleDeskAssignedMessage(): Transaction with transactionId=${transactionId} is finished and removed from state. Aborting!`);
    }

    const isMarkedForDropping = transactionIdsMarkedForDropping.includes(transactionId);
    if (!stompBrokerTopics[stompTopics.transactionDesk] || isMarkedForDropping) {
        console.log(
            `handleDeskAssignedMessage(): ${
                isMarkedForDropping ? 'Transaction marked for dropping' : 'Desk is no longer active'
            }, cleaning up state after initiation of transactionId=${transactionId}`
        );
        const initializedTransaction = yield select(transactionSelectors.selectTranasctionById(transactionId));

        if (initializedTransaction) {
            const { id: transactionId, locationId, tabId } = initializedTransaction;
            yield put(
                transactionActions.closeTransactionPanel({
                    transactionId,
                    locationId,
                    tabId,
                    closeAllPanels: false, //cleaning only state related to this specific transaction
                    closeKurentoClient: false,
                    isTransactionPageActive: true,
                })
            );
        } else {
            yield put(formControlActions.removeForm(transactionId));
        }
    }
}

function* getRelaysConfig(transactionId, gateId) {
    try {
        console.log(`getRelayConfig(): Requesting relay config for transactionId=${transactionId}, gateId=${gateId}`);
        const response = yield axiosAiq(`/relay/${gateId}`);
        yield put(formControlActions.setRelaysConfig({ transactionId, relaysConfig: response.data }));
    } catch (err) {
        console.log(`getRelayConfig(): Error getting relay config for transactionId=${transactionId}, gateId=${gateId}, message=${err?.message}`);
        yield call(notificationHandler, {
            err,
            title: 'Error getting relay config',
        });
    }
}

export function* leaveDesk(unsubscribe = true) {
    yield put(desksActions.setCurrentDesk({}));

    if (unsubscribe) {
        yield put(transactionActions.unsubscribeFromStompBrokerTopic(stompTopics.transactionDesk));
    }
    yield put(desksActions.setIsActiveDeskConfirmed(true));
    yield put(transactionActions.closeTransactionPanel({ closeAllPanels: true, closeKurentoClient: false }));

    sessionStorage.removeItem('desk');
}

export function* updateTransaction(transactionId) {
    yield put(transactionActions.setUpdatingTransactionInProgress({ isUpdateInProgress: true, transactionId }));
    yield call(updateTransactionDataAutomation, transactionId);
    yield call(getTransactionEncodedImages, transactionId);
    yield put(transactionActions.setUpdatingTransactionInProgress({ isUpdateInProgress: false, transactionId }));
}

export function* updateTransactionCameras(cameraList) {
    for (let camera of cameraList) {
        if (camera?.player_id) {
            const transactionIdsArr = kurentoManager.getTransactionIdsByCameraId(camera.id);
            console.log(`updateTransactionCameras(): updating cameraId=${camera.id}`);
            yield fork(updateCameraConfig, camera, transactionIdsArr);
        }
    }
}

function* updateTransactionDataAutomation(transactionId) {
    try {
        const response = yield axiosAiq.get(`/transactions/trucks/${transactionId}/automation`);
        const { data: automation } = response;
        const version = automation?.version;
        yield put(transactionActions.updateTransactionVersion({ transactionId, version }));
        yield put(formControlActions.updateTransactionDataAutomation({ automation }));
        console.log(
            `updateTransactionDataAutomation(): Successfuly updated transaction data for transactionId=${transactionId} to version=${version}`
        );
    } catch (err) {
        console.log(`updateTransactionDataAutomation(): Error updating transaction data for transactionId=${transactionId}, message=${err.message}`);
        yield call(notificationHandler, {
            err,
            title: 'Error updating transaction data automation',
        });
    }
}

export function* getTransactionEncodedImages(transactionId) {
    perf.mark(transactionId, perf.markNames.fetchEncodedImagesStart);
    try {
        console.log(`getTransactionEncodedImages(): Requesting alisa images, transactionId=${transactionId}`);
        const response = yield axiosAiq.get(`/transactions/trucks/${transactionId}/encoded-images`);
        console.log(`getTransactionEncodedImages(): Received alisa images, transactionId=${transactionId}`);
        const { data: encodedImages } = response;
        if (!encodedImages?.length) {
            perf.mark(transactionId, perf.markNames.fetchEncodedImagesEnd);
            return;
        }
        yield put(formControlActions.setEncodedImages({ encodedImages, transactionId }));

        console.log(`getTransactionEncodedImages(): Successfuly updated alisa images for transactionId=${transactionId}`);
    } catch (err) {
        console.log(`getTransactionEncodedImages(): Error updating alisa images for transactionId=${transactionId}, message=${err.message}`);
        yield call(notificationHandler, {
            err,
            title: 'Error getting transaction images',
        });
    }
    perf.mark(transactionId, perf.markNames.fetchEncodedImagesEnd);
}

function* handleWebSocketError() {
    yield put(
        snackbarActions.enqueueSnackbar({
            message: `WEB SOCKET ERROR! Trying to reconnect...`,
            options: {
                variant: 'error',
            },
        })
    );
    yield put(transactionActions.setActiveTab(divisionTabId));
}

function* handleError(command) {
    yield put(desksActions.setCurrentDeskInactive());
    yield put(desksActions.setCurrentDesk({}));
    yield put(
        transactionActions.closeTransactionPanel({
            closeAllPanels: true,
            closeKurentoClient: false,
        })
    );
    yield put(transactionActions.setStompBrokerError());
    yield put(transactionActions.setActiveTab(divisionTabId));
    yield call(notificationHandler, {
        title: `${command === 'ERROR' ? 'Stomp broker error' : 'Connection closed due repetitive websocket error'}, try to reload`,
    });
}

export function* subscribeToStompBrokerTopic({ payload: { topic: topicName, url: topicUrl } }) {
    yield sharedWorkerActions.subscribe({ topicName, topicUrl });
    stompBrokerTopics[topicName] = topicUrl;
}

export function* unsubscribeFromStompBrokerTopic({ payload: topicName }) {
    if (stompBrokerTopics[topicName]) {
        yield sharedWorkerActions.unsubscribe({ topicName, url: stompBrokerTopics[topicName] });
        stompBrokerTopics[topicName] = false;
    }
}

export function* checkAiqServerHealth() {
    try {
        const response = yield axios.get(`${aiqConfig.GENERAL.ADDRESSES.HTTP.HEALTH}`);

        const {
            data: { status: aiqServerHealthStatus },
        } = response;

        yield put(transactionActions.checkAIQServerHealthSuccess(aiqServerHealthStatus));

        yield put(desksActions.fetchDesksStart());
        sharedWorkerActions.subscribe({ topicName: 'allDesks', topicUrl: '/topic/all-desks' });
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: transactionActions.checkAIQServerHealthFailed,
            title: 'Error getting AIQ server health',
        });
    }
}

export function* closeTransactionPanel({
    payload: {
        transactionId,
        locationId,
        tabId,
        closeAllPanels = false,
        closeKurentoClient = true,
        isTransactionPageActive = true,
        queueFinishedTransaction = true,
    },
}) {
    if (closeAllPanels) {
        transactionIdsMarkedForDropping = [];
        const activeTab = isTransactionPageActive ? divisionTabId : 0;
        yield put(transactionActions.setActiveTab(activeTab));
        const transacions = yield select(transactionSelectors.selectTransactions);
        for (let transacion of transacions) yield put(formControlActions.removeForm(transacion.id));
        yield put(transactionActions.setTransactions([]));
        yield put(transactionActions.setUpdatingTransactionInProgress({ initialState: true }));
        yield put(transactionActions.setHasReopenedTransaction(false));
        yield put(tableControlActions.setAllTablesData({}));
        yield put(transactionActions.removeVideoWallCameras());
        yield kurentoManager.stopAllStreams(closeKurentoClient);
        yield talkManager.stopSession();
    } else {
        const forms = yield select(selectFormsObject);
        const transactions = yield select(selectTransactions);
        const activeTalkUrl = yield select(transactionSelectors.selectActiveTalkUrl);

        transactionIdsMarkedForDropping = transactionIdsMarkedForDropping.filter((id) => id !== transactionId);

        if (queueFinishedTransaction) {
            finishedTransactionIdsQueue.unshift(transactionId);
            setTimeout(() => finishedTransactionIdsQueue.pop(), yield select(selectExpectedFinishedTransactionMillis));
        }

        let locationTransactionsInUse = false;
        let stopTalkManagerSession = false;
        const filteredTransactions = transactions.filter((transaction) => {
            if (transaction.id !== transactionId && transaction.locationId === locationId) locationTransactionsInUse = true;

            if (transaction.id === transactionId && transaction.camsConfig.talkUrls.includes(activeTalkUrl)) stopTalkManagerSession = true;
            return transaction.id !== transactionId;
        });

        yield put(transactionActions.setTransactions(filteredTransactions));
        yield put(transactionActions.setUpdatingTransactionInProgress({ isUpdateInProgress: false, transactionId }));
        yield put(transactionActions.setHasReopenedTransaction(!!filteredTransactions.find(({ tabId }) => tabId === reopenedTransactionTabId)));
        const isVideoWallEnabled = yield select(transactionSelectors.selectIsVideoWallEnabled);
        const currentDesk = yield select(selectCurrentDesk);
        const newActiveTab = filteredTransactions.length
            ? filteredTransactions[0].tabId
            : currentDesk.id && isVideoWallEnabled
              ? videoWallTabId
              : divisionTabId;
        yield put(transactionActions.setActiveTab(newActiveTab));
        /* eslint-disable-next-line no-unused-vars */
        const { [transactionId]: removed, ...filteredForms } = forms;
        yield put(formControlActions.setFormsObj(filteredForms));

        if (!locationTransactionsInUse) {
            const locationTransactions = yield select(selectAllTablesData);
            /* eslint-disable no-unused-vars */
            const {
                [locationId]: removed,
                [`${locationId}_manualAnnouncements`]: announcements,
                ...filteredLocationTransactions
            } = locationTransactions;
            yield put(tableControlActions.setAllTablesData(filteredLocationTransactions));
            /* eslint-enable no-unused-vars */
        }

        if (stopTalkManagerSession) yield talkManager.stopSession();
        yield kurentoManager.closeTab(tabId, transactionId);
        console.log(`closeTransactionPanel(): transactionId=${transactionId} successfully removed from state`);
    }
}

export function* setActiveTalkUrl({ payload: { activeTalkUrl, sendSignal = true } }) {
    yield sessionStorage.setItem('talkUrl', JSON.stringify(activeTalkUrl));
    sendSignal && sendLocalStorageSignal('talkUrlChanged', activeTalkUrl);
}

export function* setActiveDemoTalkUrl({ payload: { talkUrl, sendSignal = true } }) {
    yield sessionStorage.setItem('demoTalkUrl', JSON.stringify(talkUrl));
    sendSignal && sendLocalStorageSignal('demoTalkUrlChanged', talkUrl);
}

function* registerSharedWorker() {
    const worker = getSharedWorker();
    const { username } = yield select(selectUser);
    const transactionModeOn = yield select(transactionSelectors.selectIsReceivingTransactionsEnabled);
    const videoWallModeOn = yield select(transactionSelectors.selectIsVideoWallEnabled);
    const subscriptions = [];
    transactionModeOn && subscriptions.push(stompTopics.transactionDesk);
    videoWallModeOn && subscriptions.push(stompTopics.videoWall);
    worker.port.start();
    messageType.sharedWorkerPort = worker.port;
    sharedWorkerObj.port = worker.port;
    sharedWorkerActions.registerWindow({ username, subscriptions });
    yield call(setSharedWorkerListener, worker.port);
}

function* setSharedWorkerListener(port) {
    const channel = yield call(createMessageChanel, port);
    while (channel) {
        try {
            const payload = yield take(channel);
            const { type, data } = payload;
            console.log(`SharedWorkerMessageRecieved, message type=${type}`);
            switch (type) {
                case messageType.registerWindow:
                case messageType.windowIdUpdate:
                    yield put(transactionActions.setWindowId(data));
                    sharedWorkerObj.id = data.id;
                    break;
                case messageType.stompMessage:
                    yield fork(handleStompBrokerResponse, data);
                    break;
                case messageType.deskSelected:
                    {
                        const { id: currentDeskId } = yield select(selectCurrentDesk);
                        if (currentDeskId !== data.id) yield put(desksActions.selectDesk(data));
                    }
                    break;
                case messageType.windowsCountChange:
                    yield put(transactionActions.setWindowCount(data));
                    break;
                case messageType.transactionFinished:
                    onCloseTransaction(data);
                    break;
                case messageType.deskPauseRequestSent:
                    yield put(desksActions.setPauseRequestSent(data));
                    break;
                case messageType.machineWakeUpDetected:
                    kurentoManager.restartAll();
                    break;
                default:
            }
        } catch (err) {
            console.error(err);
        }
    }
}

function onCloseTransaction(transactionIds) {
    transactionIds.forEach((transactionId) => {
        transactionIdsMarkedForDropping.push(transactionId);
        const transaction = store.getState().transactions.transactions.find((transaction) => transaction.id === transactionId);
        if (transaction) {
            const { tabId, locationId } = transaction;
            store.dispatch(
                transactionActions.closeTransactionPanel({
                    tabId,
                    transactionId,
                    locationId,
                    queueFinishedTransaction: false,
                })
            );
            store.dispatch(
                tableControlActions.fetchTableDataStart({
                    tableId: locationId,
                    locationId,
                    pageNum: 0,
                })
            );
        }
    });
}

async function createMessageChanel(port) {
    return eventChannel((emmiter) => {
        port.addEventListener('message', (e) => emmiter(e.data));
        return () => {};
    });
}

export function* onGetVideoWallCameras() {
    yield takeLatest(transactionActions.getVideoWallCameras.type, getVideoWallCameras);
}

export function* onCloseTransactionPanel() {
    yield takeLatest(transactionActions.closeTransactionPanel.type, closeTransactionPanel);
}

export function* onCheckAiqServerHealth() {
    yield takeLatest(transactionActions.checkAIQServerHealthStart.type, checkAiqServerHealth);
}

export function* onUnsubscribeFromStompBrokerTopic() {
    yield takeEvery(transactionActions.unsubscribeFromStompBrokerTopic.type, unsubscribeFromStompBrokerTopic);
}

export function* onSubscribeToStompBrokerTopic() {
    yield takeEvery(transactionActions.subscribeToStompBrokerTopic.type, subscribeToStompBrokerTopic);
}

export function* onSetActiveTalkUrl() {
    yield takeEvery(transactionActions.setActiveTalkUrl.type, setActiveTalkUrl);
}

export function* onSetActiveDemoTalkUrl() {
    yield takeEvery(transactionActions.setActiveDemoTalkUrl.type, setActiveDemoTalkUrl);
}

export function* onHandleDeskAssignedMessage() {
    const requestChan = yield actionChannel(transactionActions.deskAssignedMessageReceived.type, new ChannelBuffer()); //

    while (true) {
        const { payload } = yield take(requestChan);
        const transactionId = payload.data?.truck_transaction_id;
        const isMarkedForDropping = transactionIdsMarkedForDropping.includes(transactionId);
        const isReceivingTransactionsEnabled = yield select(transactionSelectors.selectIsReceivingTransactionsEnabled);

        if (isReceivingTransactionsEnabled && stompBrokerTopics[stompTopics.transactionDesk] && !isMarkedForDropping) {
            perf.mark(payload, perf.markNames.startingToHandle);
            yield call(handleDeskAssignedMessage, payload);
            perf.mark(payload, perf.markNames.handled);
            perf.getMeasurements(payload);
        } else {
            const message = isMarkedForDropping ? 'transaction marked for dropping' : 'receiving of transactions is disabled';
            console.log(`onHandleDeskAssignedMessage(): Dropping transaction id=${payload?.truck_transaction_id}, ${message}.`);
        }
    }
}

export function* onToggleVideoWallEnabled() {
    yield takeEvery(transactionActions.toggleVideoWallEnabled.type, toggleVideoWallEnabled);
}

export function* onToggleIsReceivingTransactionsEnabled() {
    yield takeEvery(transactionActions.toggleIsReceivingTransactionsEnabled.type, toggleIsReceivingTransactionsEnabled);
}

export function* onRegisterSharedWorker() {
    yield takeLeading(transactionActions.registerSharedWorker, registerSharedWorker);
}

////REPORT ISSUES

export function* sendReportedIssues({ payload }) {
    try {
        const response = yield axiosAiq.post(payload.url, payload.data[0]);

        if (response.status === 200) {
            yield call(notificationHandler, {
                title: 'Successfully reported issues',
                variant: 'success',
            });
        }
    } catch (err) {
        var mySubString = err.response.data.error.substring(err.response.data.error.indexOf('[') + 1, err.response.data.error.indexOf(']'));
        yield call(notificationHandler, {
            title: `Not able to report issues, ${mySubString ? mySubString : err.response.data.error}`,
        });
    }
}

export function* onSendReportedIssues() {
    yield takeEvery(transactionActions.reportIssues.type, sendReportedIssues);
}

// setActiveDemoTalkUrl
export function* transactionsSaga() {
    yield all([
        call(onCheckAiqServerHealth),
        call(onCloseTransactionPanel),
        call(onUnsubscribeFromStompBrokerTopic),
        call(onSubscribeToStompBrokerTopic),
        call(onSetActiveTalkUrl),
        call(onSetActiveDemoTalkUrl),
        call(onHandleDeskAssignedMessage),
        call(onGetVideoWallCameras),
        call(onToggleVideoWallEnabled),
        call(onToggleIsReceivingTransactionsEnabled),
        call(onRegisterSharedWorker),
        call(onSendReportedIssues),
    ]);
}

class ChannelBuffer {
    #messages = [];
    isEmpty() {
        return !this.#messages.length;
    }
    put(message) {
        this.#messages.push(message);
        console.log(
            `ChannelBuffer.put(): transactionId=${message?.payload?.truck_transaction_id}, version=${
                message?.payload?.version
            }, put to buffer, buffer size=${this.#messages.length} `
        );
        perf.mark(message.payload, perf.markNames.putToBuffer);
    }
    take() {
        const message = this.#messages.shift();
        console.log(
            `ChannelBuffer.take(): transactionId=${message?.payload?.truck_transaction_id}, version=${
                message?.payload?.version
            } taken from buffer, buffer size=${this.#messages.length} `
        );
        perf.mark(message.payload, perf.markNames.takenFromBuffer);
        return message;
    }
}
