import { call, put, takeEvery } from 'redux-saga/effects';
import { formControlActions } from '../formControlSlice';
import { fieldMappings } from 'components/transaction/components/panels/transaction/announcements/announcementsConfig';
import { axiosAiq } from 'config';
import { notificationHandler } from 'utils/utils';

class CopyToFormSagas {
    init = () => {
        return [call(this.fromBisonAnnouncementTable.bind(this)), call(this.fromLocationTransactionsTable.bind(this))];
    };

    *fromBisonAnnouncementTable() {
        yield takeEvery(formControlActions.copyBisonAnnouncement.type, this.copyBisonAnnouncement.bind(this));
    }

    *fromLocationTransactionsTable() {
        yield takeEvery(formControlActions.copyLocationTransaction.type, this.copyLocationTransaction.bind(this));
    }

    *copyBisonAnnouncement({ payload: { transactionId, tableName: table, values, key, copy } }) {
        const tableName = copy ? table : '';
        const internalIdAnnouncementUsed = copy ? key : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, transactionId, values)));
        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));
        yield put(formControlActions.setAnnouncementIdUsed({ formId: transactionId, internalIdAnnouncementUsed }));
        copy && (yield put(formControlActions.setFieldValue({ formId: transactionId, fieldName: 'sendToBison', value: true })));
        function getAutomationValuesActionObj(select, transactionId, values) {
            if (select)
                return {
                    transactionId,
                    automationObjTemplate: { source: 'Bison' },
                    customSource: { values, fieldMappings },
                };
            return { transactionId, setAlisaAutomation: true };
        }
    }

    *copyLocationTransaction({ payload: { transactionId, announcementsMode, copy, tableName: table, key } }) {
        if (copy) {
            try {
                const urlPath = `/transactions/trucks${announcementsMode ? '/announcements' : ''}/from/${key}/to/${transactionId}`;
                const { data: transactionData } = yield axiosAiq.get(urlPath);
                yield put(formControlActions.setTransactionData({ transactionData }));
            } catch (err) {
                yield notificationHandler({ err, title: `ERROR COPYING DATA TO FORM` });
                return;
            }
        }

        const manualAnnouncementIdUsed = copy && announcementsMode ? key : '';
        const tableName = copy ? table : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, announcementsMode, transactionId)));

        yield put(formControlActions.setAnnouncementIdUsed({ formId: transactionId, manualAnnouncementIdUsed }));

        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));

        function getAutomationValuesActionObj(select, announcementsMode, transactionId) {
            const source = announcementsMode ? 'Manual' : 'Copied';
            if (select) return { transactionId, automationObjTemplate: { source } };
            return { transactionId, setAlisaAutomation: true };
        }
    }
}

export default new CopyToFormSagas();
