import { useRef } from 'react';
import styles from './Note.module.css';
import { PERIOD_ACCESS_TYPES } from 'utils/constants';
import { renderLimitedTimeAccess, renderPermanentAccess } from './NoteUtils';

function Note({ mode, hovered, name, note, color, accessPeriod }) {
    const isHoverMode = mode === 'hover';
    const { accessType = '', startTime = '', endTime = '', weekdays = [], periodType = '' } = accessPeriod;
    const isVisible = (isHoverMode && hovered) || !isHoverMode;
    const noteRef = useRef();

    const alertNameString = `${name}${note ? ': ' : ''}`;

    //styles
    const containerStyles = `${styles.note}${!isVisible ? ` ${styles['hidden']}` : ''}${isHoverMode ? ` ${styles['note--absolute']}` : ''}`;
    const inlineStyle = {
        backgroundColor: color,
        width: noteRef?.current?.closest('div[class*=AIQInput_input]')?.getBoundingClientRect()?.width || '340px',
    };

    return (
        <div className={containerStyles} {...(isHoverMode && { style: inlineStyle })} ref={noteRef}>
            <p>
                <span>{alertNameString}</span>
            </p>
            <p>{note}</p>
            {accessType && (
                <p>
                    &bull; Period Access: {PERIOD_ACCESS_TYPES[accessType]} {renderPermanentAccess(startTime, endTime)}
                </p>
            )}
            {periodType && periodType !== 'NO_LIMIT' && (
                <>
                    <p>&bull; Limited-time Access:</p>
                    <p className={styles['limited']}>{renderLimitedTimeAccess(weekdays)}</p>
                </>
            )}
        </div>
    );
}

export default Note;
