import { useEffect } from 'react';
import { useState } from 'react';
import Option from './Option/Option';

function Options(props) {
    const { filteredOptions, value, setInputValue, getInputValue, hasAlerts, referenceExpr, setReferenceValue } = props;
    const [hoveredOption, setHoveredOption] = useState(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

    useEffect(() => {
        function keydowHandler(e) {
            const optionsNum = filteredOptions.length - 1;
            const optionIndex = getNextOptionIndex(e, selectedOptionIndex, optionsNum);
            if (optionIndex >= 0) {
                const option = filteredOptions[optionIndex];
                setInputValue(getInputValue(option));
                setSelectedOptionIndex(optionIndex);
                referenceExpr && setReferenceValue(option?.[referenceExpr]);
            } else referenceExpr && setReferenceValue(-1);

            setHoveredOption(null);
        }
        document.addEventListener('keydown', keydowHandler);

        return () => {
            document.removeEventListener('keydown', keydowHandler);
        };
    }, [filteredOptions, selectedOptionIndex, setInputValue, referenceExpr, getInputValue, setReferenceValue]);

    return (
        <>
            {filteredOptions.map((option, i) => {
                const activeOption = option === value;
                const hovered = option === hoveredOption;
                const key = option.duplicate ? getInputValue(option) + option.duplicate : getInputValue(option);
                return (
                    <Option
                        key={key}
                        optionIndex={i}
                        option={option}
                        setHoveredOption={setHoveredOption}
                        activeOption={activeOption}
                        hoveredOption={hovered}
                        selectedOption={selectedOptionIndex === i}
                        setSelectedOptionIndex={setSelectedOptionIndex}
                        hasAlerts={hasAlerts}
                        {...props}
                    />
                );
            })}
        </>
    );
}

export default Options;

function getNextOptionIndex(e, currentOptionIndex, optionsNum) {
    let optionIndex;
    if (e.key === 'ArrowDown') {
        const nextIndex = currentOptionIndex + 1;
        optionIndex = nextIndex <= optionsNum ? nextIndex : 0;
    }
    if (e.key === 'ArrowUp') {
        const nextIndex = currentOptionIndex - 1;
        optionIndex = nextIndex >= 0 ? nextIndex : optionsNum;
    }

    if (e.key === 'Enter') {
        focusNextInputField(+e.target.id);
        return currentOptionIndex;
    }

    if (e.key === 'Tab') return currentOptionIndex;

    return optionIndex;
}

//TODO  disabling eslint warining for now, export function to separate file
// eslint-disable-next-line react-refresh/only-export-components
export const focusNextInputField = function (currentInputFieldId) {
    const inputToFocus = document.getElementById(`${+currentInputFieldId + 1}`);
    inputToFocus && setTimeout(() => inputToFocus.focus(), 0);
};
