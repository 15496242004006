import Alerts from 'components/UI/Alerts/Alerts';
import { useEffect } from 'react';
import { useRef } from 'react';
import { focusNextInputField } from '../Options';
import styles from './Option.module.css';
import AdditionalInfo from './AdditionalInfo';

// const alerts = [
//     { text: 1, color: '#16A085' },
//     { color: '#F1C40F' },
//     { color: '#C0392B' },
// ];

function Option({
    option,
    onChange,
    setDropdownOpened,
    setInputValue,
    activeOption,
    setHoveredOption,
    hoveredOption,
    selectedOption,
    setSelectedOptionIndex,
    optionIndex,
    getInputValue,
    hasAlerts,
    referenceExpr,
    setReferenceValue,
}) {
    const optionLabel = getInputValue(option);
    const optionRef = useRef();

    useEffect(() => {
        if (activeOption) setSelectedOptionIndex(optionIndex);
    }, [activeOption, optionIndex, setSelectedOptionIndex]);

    useEffect(() => {
        if (selectedOption)
            optionRef.current.scrollIntoView({
                block: 'nearest',
                inline: 'start',
            });
    }, [selectedOption]);

    const optionStyles = `${styles['option-container']}${hasAlerts ? ` ${styles['option-container--alerts']}` : ''} ${styles['option']}${
        activeOption ? ` ${styles['option--active']}` : ''
    }${hoveredOption ? ` ${styles['option--highlighted']}` : selectedOption ? ` ${styles['option--selected']}` : ''}`;

    const mouseDownHandler = (e) => {
        setInputValue(getInputValue(option));
        onChange(e, option);
        setDropdownOpened(false);
        const autocompleteContainer = optionRef.current.closest('div[class*="AIQAutocomplete"]');
        const currentInputFieldId = +autocompleteContainer.querySelector('input').id;
        referenceExpr && setReferenceValue(option?.[referenceExpr]);
        focusNextInputField(currentInputFieldId);
    };

    const mouseOverHandler = () => {
        setHoveredOption(option);
        setSelectedOptionIndex(optionIndex);
    };
    const alerts = option.alerts || null;
    return (
        <div className={optionStyles} onMouseDown={mouseDownHandler} onMouseOver={mouseOverHandler}>
            <p className={styles[`option-text${hasAlerts ? '--alerts' : ''}`]} ref={optionRef}>
                {optionLabel}
            </p>
            <Alerts alerts={alerts} containerClasses={styles.alert} />
            <AdditionalInfo option={option} hasAlerts={hasAlerts} />
        </div>
    );
}

export default Option;
